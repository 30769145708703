@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600&display=swap");
* {
  font-family: "Poppins", sans-serif;
}

body {
  background: hsla(197, 14%, 57%, 1);

  background: linear-gradient(
    90deg,
    hsla(197, 14%, 57%, 1) 0%,
    hsla(192, 17%, 94%, 1) 100%
  );

  background: -moz-linear-gradient(
    90deg,
    hsla(197, 14%, 57%, 1) 0%,
    hsla(192, 17%, 94%, 1) 100%
  );

  background: -webkit-linear-gradient(
    90deg,
    hsla(197, 14%, 57%, 1) 0%,
    hsla(192, 17%, 94%, 1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#8399A2", endColorstr="#EEF2F3", GradientType=1 );
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.navTab {
  margin-bottom: 50px;
}

.noActive:visited,
.noActive:link,
.noActive:active {
  color: black;
}
.noActive:focus {
  background: #2c698d40;
  outline: none;
  -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
  -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
  box-shadow: inset 0px 0px 5px #c1c1c1;
}

.svgContainer {
  display: flex;
  gap: 1em;
}
.linkedin,
.github {
  color: black;
}
.linkedin svg:hover {
  fill: #2c698d;
}

.skillsSection {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
  flex-wrap: wrap;
  flex-direction: column;
 
}
.skillsContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 2%;
  margin-right: 50px;
  margin-left: 50px;
  border-radius: 10px;
  gap: 2em;
  padding: 1.2em;
  max-width: 65rem;
}
.skillsHeader {
  justify-content: center;
  text-decoration: underline;
}

.github svg:hover {
  fill: #2c698d;
  text-decoration: none;
}

.projectsDiv {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  align-content: center;
  gap: 2em;
}
.projectContainer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  flex-direction: column;
  padding-top: 10%;
}
.projectsHeader {
  display: flex;
  justify-content: center;
  padding: 1em;
  text-decoration: underline;
}
.cardsContainer {
  display: flex;
  justify-content: center;
}
@keyframes myButtonAnim {
	0%,
	100% {
		transform: translateY(0) rotate(0);
		transform-origin: 50% 50%;
	}

	15% {
		transform: translateY(-30px) rotate(6deg);
	}

	30% {
		transform: translateY(15px) rotate(-6deg);
	}

	45% {
		transform: translateY(-15px) rotate(3.6deg);
	}

	60% {
		transform: translateY(9px) rotate(-2.4deg);
	}

	75% {
		transform: translateY(-6px) rotate(1.2deg);
	}
}



@keyframes myAnim {
	0% {
		animation-timing-function: ease-in;
		opacity: 1;
		transform: translateY(-45px);
	}

	24% {
		opacity: 1;
	}

	40% {
		animation-timing-function: ease-in;
		transform: translateY(-24px);
	}

	65% {
		animation-timing-function: ease-in;
		transform: translateY(-12px);
	}

	82% {
		animation-timing-function: ease-in;
		transform: translateY(-6px);
	}

	93% {
		animation-timing-function: ease-in;
		transform: translateY(-4px);
	}

	25%,
	55%,
	75%,
	87% {
		animation-timing-function: ease-out;
		transform: translateY(0px);
	}

	100% {
		animation-timing-function: ease-out;
		opacity: 1;
		transform: translateY(0px);
	}
}

.jsContainer,
.htmlContainer,
.bulmaContainer,
.nodeContainer,
.cssContainer,
.reactContainer,
.muiContainer,
.mongodbContainer,
.mySqlContainer,
.redwoodContainer,
.railwayContainer,
.herokuContainer,
.netlifyContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.ani{
  animation: myButtonAnim 3s ease 0s 1 normal forwards;
}
.aboutContainer {
  max-width: 1100px;
}
.aboutSection {
  padding: 7em;
  display: flex;
  justify-content: center;
  gap: 1em;
  max-height: 300px;
  align-items: center;
  flex-wrap: wrap;
}
.imgCapture {
  border-radius: 50%;
  max-height: 300px;
}
.aboutTextContainer {
  max-width: 75%;
  padding: 1rem;
  min-width: 200px;
}

.homeTextContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  margin-top: 200px;
  
}
.typeWriterContainer {
  display: flex;
  justify-content: center;
  align-content: center;
  align-text: center;
  font-size: 2rem;
  padding: 1em;
}

/* footer */
.copyrightContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  position: static;
  bottom: 0;
  margin-top: 60%;
  padding: 0;
}

/* Media Queries */
@media only screen and (max-width: 1500px) {
  .aboutSection {
    padding: 7em;
    display: flex;
    justify-content: center;
    gap: 1em;
    max-height: 400px;
    align-items: center;

    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 380px) {
  .aboutSection {
    display: flex;
    justify-content: center;
    max-height: 400px;
    max-width: 85%;
    align-items: center;
    flex-wrap: wrap;
  }
  .aboutTextContainer {
    max-width: 75%;
    min-width: 200px;
  }
}
